import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Error } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Error",
  "componentId": "error",
  "description": "Errors are used to provide error messaging paired with form fields.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Please visit the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/styles/typography"
        }}>{`Typography`}</a></strong>{` page for more information on using the Error component.`}</p>
    <p><strong parentName="p">{`Documentation in process----------------`}</strong></p>
    <h2><strong parentName="h2">{`Variations`}</strong></h2>
    <PatternExample example={<Error mdxType="Error">This is how an error will be displayed.</Error>} title="Error Message" mdxType="PatternExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      